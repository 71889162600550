<template>
    <div class="page-warp gray-page min-page">
        <van-tabs v-model="active" @click="resetLoad()">
            <van-tab title="全部"></van-tab>
            <van-tab name="5" title="已签约"></van-tab>
            <van-tab name="6" title="未签约"></van-tab>
            <van-tab name="1,2,3,4" title="签约中"></van-tab>
        </van-tabs>
        <div class="scroll-box">
             <div class="row loop-list mt-10"  v-for="(item,inx) in loopList" :key="inx">
                <div class="row loop-title"><span class="nums">{{item.companyName}}</span><span class="btns">{{item.companyStatusStr}} </span></div>
                <div class="row loop-cons">
                    <!-- <span class="fz-12">{{item.companyName}}</span> -->
                    <span class="fz-12">园区：{{item.gardenName}}</span>
                    <span class="fz-12">手机号：{{item.linkerMobile}}</span>
                    <span class="fz-12">姓名：{{item.linkerName}}</span>
                    <span class="fz-12">创建时间：{{item.createTime}}</span>
                </div>
            </div>
            <infinite-loading 
                v-if="!finished" 
                @infinite="onLoad"
                ref="infiniteLoading">
            </infinite-loading>
            <div v-else class="row no-datas">没有更多了</div>
        </div>
    </div>
</template>
<script>
import { pageQuery } from "@/api/client"
import InfiniteLoading from "vue-infinite-loading";
export default {
    data() {
        return {
            active:'',
            loading: false,
            finished: false,
            page:1,
            pageSize:10,
            loopList:[],
        };
    },
    components: {
        InfiniteLoading
    },
    methods: {
        resetLoad(){
            this.loopList = [];
            this.page = 1;
            this.loading = false;
            this.finished = false;
            this.onLoad()
        },
        async onLoad($state) {
            if(this.loading) return false;
            // 异步更新数据
            let pramas={
                pageNum:this.page,
                pageSize:this.pageSize
            }
            if(this.active){
                pramas.companyStatusS = this.active;
            }
            this.loading = true;
            await pageQuery (pramas).then(res=>{
                this.loopList = this.loopList.concat(res.rows);
                if (res.total <= this.loopList.length) {
                    this.finished = true;
                }
                this.page +=1
                setTimeout(()=>{
                    this.loading = false;
                    
                    if(this.$refs.infiniteLoading) this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded'); //完成加载
                },1000)
            })
        },
    },
};
</script>
<style>

</style>
